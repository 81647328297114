import Image from "next/image";
import InlineLoader from "@/icons/inline-loader.svg";
import classNames from "classnames";

function TagCard({ isLoading, onClick, cardData }) {
  return (
    <div className="p-4 flex items-center flex-col justify-center bg-white shadow-pet_tag-card rounded-[14px]">
      <p className="text-[#B9C1CB] text-sm font-bold text-center">
        {cardData.title}
      </p>

      <p className="text-[#050426] text-base lg:text-lg font-bold text-center">
        {cardData.heading_1} <br /> {cardData.heading_2}
      </p>

      <div className="w-[108px] h-[150px]  relative">
        <Image
          src={cardData.image}
          fill
          alt="pet-tag"
          className="object-cover"
        />
      </div>

      <button
        className="relative px-4 py-3 text-[#F5FCFF] bg-[#FF7A00] rounded-[10px] w-full"
        disabled={isLoading}
        onClick={onClick}
      >
        {isLoading && (
          <InlineLoader className="inline-block h-[3rem] w-[3rem] absolute top-[calc(50%_-_1.5rem)] left-[calc(50%_-_1.5rem)]" />
        )}
        <div
          className={classNames({
            invisible: isLoading,
          })}
        >
          <p>{cardData.btnText}</p>
        </div>
      </button>
    </div>
  );
}

export default TagCard;
