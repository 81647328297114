import { Modal } from ".";
import Image from "next/image";
import CloseCircle from "@/icons/close-circle-bold.svg";
import TagCard from "./TagCard";
import { useAddToCart } from "repo/cart";
import {
  PET_TAG_COMBO_CATEGORY,
  PET_TAG_DATA,
} from "@/utils/constants/pet-tag";
import { useRouter } from "next/navigation";
import { PET_TAG_MODAL_DATA } from "@/utils/constants/pet-tag";
import { useDispatch } from "react-redux";
import { updateCartItemCount } from "@/store/feature/cartSlice";
import successToast, { errorToast } from "@/utils/toast";

export default function PetTagModal({ isActive, closeModal }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { isLoading, mutate } = useAddToCart({
    onSuccess: (cartData) => {
      dispatch(updateCartItemCount(cartData?.data?.cart?.products?.length));
      successToast("Product added to cart successfully");
      closeModal();
    },
    onError: (error) => {
      errorToast(error.message);
    },
  });

  return (
    <Modal isOpen={isActive}>
      <Modal.Content>
        <section className="relative z-50 pt-12 pb-14 px-2 lg:px-14 rounded-2xl shadow-[0px_36px_51px_0px_rgba(0,0,0,0.1)] bg-[#F5FCFF] ">
          <Image
            src="/assets/pet-tag/pet-tag-modal-bg.png"
            fill
            alt="bg"
            quality={100}
            className="z-[-10] rounded-2xl object-cover"
          />

          <Modal.Close onClick={closeModal}>
            <div className="absolute top-4 right-[18px] cursor-pointer">
              <CloseCircle />
            </div>
          </Modal.Close>

          <div className="mb-6 space-y-1">
            <h2 className="text-2xl text-[#FF7A00] font-bold text-center">
              Limited Time Offer
            </h2>
            <p className="text-lg text-[#050426] text-center">
              Get Exiting Combos
            </p>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <TagCard
              onClick={() => mutate(PET_TAG_DATA)}
              isLoading={isLoading}
              cardData={PET_TAG_MODAL_DATA[0]}
            />

            <TagCard
              onClick={() => router.push(`/category/${PET_TAG_COMBO_CATEGORY}`)}
              cardData={PET_TAG_MODAL_DATA[1]}
            />
          </div>
        </section>
      </Modal.Content>
    </Modal>
  );
}
