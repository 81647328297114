import React from "react";
import Image from "next/image";
import Link from "next/link";

function BrandDropdown({ bandOptions = [] }) {
  return (
    <div className="group relative">
      <button className="text-grayD2 group-hover:text-[#E81E61] font-medium py-2 rounded inline-flex items-center">
        <span className="text-body1">Brands</span>
        <svg
          className="fill-current h-4 w-4 ml-[.5rem]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </button>
      <div
        className={`hidden absolute text-[#1F2A3B] bg-white group-hover:block drop-shadow-[0_14px_42px_rgba(10,16,26,0.1)] rounded-[.75rem] w-auto z-[100] right-0 border max-h-[600px] overflow-y-auto customScrollBar`}
      >
        <div className="pt-[2.25rem] px-[2.25rem] pb-[1.5rem] min-w-[34rem] lg:w-[47.75rem] xl:w-[54.60rem]">
          <p className="text-[#1F2A3B] font-semibold text-[1.125rem] mb-[1.75rem]">
            Shop by Brands
          </p>

          <div className="flex items-center flex-wrap gap-x-[1rem] gap-y-[0.75rem] w-full">
            {bandOptions.map((brand) => (
              <Link href={`/brands/${brand.slug}`} key={brand.slug}>
                <div className="w-[100px] h-[100px] relative cursor-pointer">
                  <Image
                    alt={brand.name}
                    src={brand.logo}
                    fill
                    className="object-contain"
                    sizes="640px"
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandDropdown;
