import apiClient from "@/services/api-client";
import { useQuery, useMutation } from "@tanstack/react-query";
import { errorLogger } from "@/utils/helpers/logger";

const fetchCartInfo = async () => {
  return await apiClient({
    method: "get",
    url: "/v2/carts",
  });
};

const addToCartApi = async (data) => {
  return await apiClient({
    method: "post",
    url: "/v2/carts",
    data,
  });
};

const updateCartApi = async () => {
  return await apiClient({
    method: "put",
    url: "/v2/carts",
  });
};

export const useFetchCartInfo = (useQueryOptions) => {
  return useQuery({
    queryKey: ["cart-details"],
    queryFn: fetchCartInfo,
    ...useQueryOptions,
    select: (data) => {
      const apiData = data.data?.data;
      const cartData = apiData?.cart;
      const recommendedProduct = apiData?.suggestion_products || [];

      return { cartData, recommendedProduct };
    },
    onError: (error) => {
      errorLogger.logApiError(error);
    },
  });
};

export const useUpdateCart = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(updateCartApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        data: res.data?.data,
        message: res.data.message,
      });
    },
    onError: (error) => {
      errorLogger.logApiError(error);
      onError({
        status: error.response.status,
        errors: error.response.data?.errors,
        message: error.message,
      });
    },
  });
};

export const useAddToCart = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(addToCartApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        data: res.data?.data,
        message: res.data.message,
      });
    },
    onError: (error) => {
      errorLogger.logApiError(error);

      onError({
        status: error.response.status,
        errors: error.response.data?.errors,
        message: error.response.data?.message || error.message,
      });
    },
  });
};
