import PetTagModal from "@/components/petTag/modal/PetTagModal";
import { useState } from "react";
import dynamic from "next/dynamic";
import LeftSection from "./Left";
const RightSection = dynamic(() => import("./Right"), {
  ssr: false,
});

function PetTag() {
  const [isActive, setIsActive] = useState(false);

  function TagModalActive() {
    setIsActive(true);
  }

  return (
    <section>
      <div className="bg-[url('/assets/pet-tag/pet-tag-landing-bg-small.png')] lg:bg-[url('/assets/pet-tag/pet-tag-landing-bg-large.png')] bg-cover w-full h-auto lg:h-[713px] overflow-hidden text-pet-tag my-[80px]">
        <div className="flex flex-col items-center lg:flex-row">
          <LeftSection />
          <RightSection onClick={TagModalActive} />
        </div>
      </div>
      <PetTagModal isActive={isActive} closeModal={() => setIsActive(false)} />
    </section>
  );
}

export default PetTag;
