import Image from "next/image";

function LeftSection() {
  return (
    <div className="lg:w-[50%] relative">
      <div className="relative w-fit">
        <div className="relative w-[360px] h-[431px] md:w-[450px] md:h-[539px] lg:block lg:w-[500px] lg:h-[598px] xl:w-[600px] xl:h-[718px] -left-[1rem] xl:left-[50px] z-10">
          <Image
            src="/assets/pet-tag/pet-tag-landing-large.png"
            fill
            alt="Smart Pet Tag"
            priority
            objectFit="cover"
          />
        </div>
      </div>
    </div>
  );
}

export default LeftSection;
