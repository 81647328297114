export const SHOWCASE_IMAGES_URL = [
  "/assets/pet-tag/pet1.png",
  "/assets/pet-tag/pet2.png",
  "/assets/pet-tag/pet3.png",
  "/assets/pet-tag/pet4.png",
  "/assets/pet-tag/pet5.png",
  "/assets/pet-tag/pet6.png",
  "/assets/pet-tag/pet7.png",
  "/assets/pet-tag/pet8.png",
  "/assets/pet-tag/pet9.png",
  "/assets/pet-tag/pet10.png",
  "/assets/pet-tag/pet11.png",
  "/assets/pet-tag/pet12.png",
  "/assets/pet-tag/pet13.png",
  "/assets/pet-tag/pet14.png",
  "/assets/pet-tag/pet15.png",
  "/assets/pet-tag/pet16.png",
];

const server = process.env.NEXT_PUBLIC_SERVER;

const productMap = {
  DEV: {
    product: "amarpet-smart-pet-tag-with-nfc",
    variation: "BUUKQOJNOL",
  },

  STAGING: {
    product: "amarpet-smart-pet-tag-with-nfc",
    variation: "AAFTXNQTDN",
  },

  PROD: {
    product: "amarpet-smart-pet-tag-with-nfc",
    variation: "YRHONYSNHR",
  },
};

export const PET_TAG_DATA = {
  ...productMap[server],
  quantity: 1,
};

export const PET_TAG_MODAL_DATA = [
  {
    title: "Pet Tag Only",
    heading_1: "Smart",
    heading_2: "Pet Tag",
    image: "/assets/pet-tag/pet-tag-image.jpg",
    btnText: "Add to Cart",
  },

  {
    title: "Combo Offer",
    heading_1: "Smart Pet",
    heading_2: "Tag + Pet Collar",
    image: "/assets/pet-tag/combo-brand.png",
    btnText: "Check Combo",
  },
];

const comboCategoryMap = {
  DEV: "dog-food",

  STAGING: "cat",

  PROD: "smart-pet-tag-combo",
};

export const PET_TAG_COMBO_CATEGORY = comboCategoryMap[server];
