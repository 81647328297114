import * as Dialog from "@radix-ui/react-dialog";

export function Modal({ children, isOpen, onOpenChange }) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange} className="z-[1000]">
      {children}
    </Dialog.Root>
  );
}

function ModalContent({ children }) {
  return (
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 z-[999] grid items-center overflow-y-auto bg-black/80" />
      <Dialog.Content
        className={`fixed left-[50%] top-[50%] z-[1000] w-full  lg:max-w-[40rem] max-h-[95vh]  -translate-x-[50%] -translate-y-[50%] rounded-lg p-3 lg:p-6 focus:outline-none overflow-hidden`}
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        {children}
      </Dialog.Content>
    </Dialog.Portal>
  );
}

Modal.Trigger = Dialog.Trigger;
Modal.Content = ModalContent;
Modal.Close = Dialog.Close;
